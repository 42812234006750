import { FunctionComponent, SyntheticEvent, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { XMarkIcon } from '@heroicons/react/24/outline'
import { timeSlots, startSectors } from './config';

type Props = {
  flight: Flight;
  openFlight: (time: string) => void;
  closeFlight: (time: string, sectorId: string) => void;
};

const Main: FunctionComponent<Props> = ({ flight, openFlight, closeFlight }) => {

  const [showQRCode, setShowQRCode] = useState<boolean>(false);

  const handleOpenFlightSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      time: { value: string };
    };
    openFlight(target.time.value);
  }

  const handleCloseFlightSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      time: { value: string };
      sectorId: { value: string };
    };
    closeFlight(target.time.value, target.sectorId.value);
  }

  if (showQRCode && flight.QRPayload) {
    return (
      <div className="flex items-center flex-col">
        <QRCodeSVG
          value={flight.QRPayload}
          size={300}
          bgColor={"#ffffff"}
          fgColor={"#000000"}
          level={"Q"}
          includeMargin={false}
        />
        <div className="pt-12">
          <button
            type="button"
            className="rotate-180 opacity-40 text-indigo-700 border border-indigo-700 hover:bg-indigo-700 hover:text-white font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center"
            onClick={() => setShowQRCode(false)}
          >
            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            <span className="sr-only">Back</span>
          </button>
        </div>
      </div>);
  }

  return (
    <div className="h-max grid gap-8 grid-cols-1 grid-rows-auto py-12 px-8">
      {flight.hasOpenFlight ? (
        <form onSubmit={handleCloseFlightSubmit} action="#" method="POST">
          <div className="pb-20"><button
            type="button"
            className="block w-full rounded-md border border-transparent bg-sky-600 px-4 py-4 text-xl font-medium text-white shadow-sm hover:bg-sky-700"
            onClick={() => setShowQRCode(true)}
          >
            Display QR Code
          </button></div>
          <CloseButton />
          <TimeSelect />
          <SectorSelect />
        </form>
      ) : (
        <form onSubmit={handleOpenFlightSubmit} action="#">
          <OpenButton />
          <TimeSelect />
        </form>
      )}
    </div>
  );
}

export default Main;


function TimeSelect() {

  const getHour = () => {
    // the code may produce incorrect time like 25:00, but anyway it's out of required range
    const currentHour = ((new Date()).getUTCHours()+3).toString().padStart(2, '0') + ':00';
    if (currentHour in timeSlots) return currentHour;
    const firstHour = Object.keys(timeSlots)[0];
    if (currentHour < firstHour) return firstHour;
    return Object.keys(timeSlots).pop()
  }

  return (
    <select
      id="time"
      name="time"
      className="mt-5 block w-full rounded-md bg-inherit border border-gray-300 py-2 pl-3 pr-10 text-base text-gray-500"
      defaultValue={getHour()}
    >
      {Object.keys(timeSlots).map((key) => <option key={key} value={key}>{timeSlots[key]}</option>)}
    </select>
  );

}

function SectorSelect() {
  return (
    <select
      id="sectorId"
      name="sectorId"
      className="mt-2 block w-full rounded-md bg-inherit border border-gray-300 py-2 pl-3 pr-10 text-base text-gray-500"
      defaultValue="10"
    >
      {Object.keys(startSectors).map((key) => <option key={key} value={key}>{startSectors[key]}</option>)}
    </select>
  );

}

const OpenButton: FunctionComponent = () => SliderButton("Open flight", "open", "bg-emerald-400");
const CloseButton: FunctionComponent = () => SliderButton("Close flight", "close", "bg-rose-400");


function SliderButton (text: string, type: string, bgColor: string) {

  function mouseUp(e: SyntheticEvent) {
    const slider = e.target as HTMLInputElement;
    if(slider.value === '1000') {
      slider.form?.requestSubmit();
    } else {
      setTimeout(() => {
        slider.value='0';
      }, 100);
    }
  }

  return (
    <div className="relative">
      <div className="custom-slider-btn-cover text-xl font-medium text-white shadow-sm"><span>{text}</span></div>
      <input onMouseUp={mouseUp} onTouchEnd={mouseUp} type="range" defaultValue="0" min="0" max="1000" className={["custom-slider-btn", type, bgColor].join(" ")}/>
    </div>
  );
}
