export const baseUrl = 'https://hammerhead-app-zeuyz.ondigitalocean.app';

export const timeSlots: Record<string, string> = {
  '08:00': '08:00 - 09:00',
  '09:00': '09:00 - 10:00',
  '10:00': '10:00 - 11:00',
  '11:00': '11:00 - 12:00',
  '12:00': '12:00 - 13:00',
  '13:00': '13:00 - 14:00',
  '14:00': '14:00 - 15:00',
  '15:00': '15:00 - 16:00',
  '16:00': '16:00 - 17:00',
  '17:00': '17:00 - 18:00',
  '18:00': '18:00 - 19:00',
  '19:00': '19:00 - 20:00',
  '20:00': '20:00 - 21:00',
};

export const startSectors: Record<string, string> = {
  '10': '1200mt',
  '11': '1700mt',
  '12': '1800mt',
  '13': '1900mt North',
  '14': '1900mt Patara',
};
