import { useState } from 'react';
import axios, { AxiosError } from 'axios';
import Login from './Login';
import Preloader from './Preloader';
import Main from './Main';
import Error from './Error';
import { baseUrl } from './config';
import ShareMe from './ShareMe';


function App() {

  const [flight, setFlight] = useState<Flight>();
  const [credentials, setCredentials] = useState<AppCredentials>();
  const [error, setError] = useState<AppError | undefined>();
  const [share, setShare] = useState(false);

  const processErrorResponse = (error: AxiosError<any, any>) => {
    console.error('Error:', error);
    if (error.code === "ERR_BAD_REQUEST" && error.response?.status === 400) {
      const data = error.response.data;
      setError({
        code: data.error,
        message: Array.isArray(data.message)?data.message:[data.message],
      });
    } else {
      setError({code: error.code||'Unknown', message: [error.message]});
    }
  }

  const getFlightRequest = async (credentials: AppCredentials) => {
    return axios.post(baseUrl+'/flight/get', credentials)
      .then(res => {
        setFlight(res.data);
      })
      .catch(processErrorResponse);
  }

  const openFlightRequest = async (credentials: AppCredentials, time: string) => {
    return axios.post(baseUrl+'/flight/open', { ...credentials, time: time }
      ).then(res => {
        getFlightRequest(credentials);
      })
      .catch(processErrorResponse);
  }

  const closeFlightRequest = async (credentials: AppCredentials, time: string, sectorId: string) => {
    return axios.post(baseUrl+'/flight/close',{ ...credentials, time: time, sectorId: sectorId }
      ).then(res => {
        getFlightRequest(credentials);
      })
      .catch(processErrorResponse);
  }

  const openFlight = (time: string) => {
    setFlight(undefined);
    setError(undefined);
    credentials && openFlightRequest(credentials, time);
  }

  const closeFlight = (time: string, sectorId: string) => {
    setFlight(undefined);
    setError(undefined);
    credentials && closeFlightRequest(credentials, time, sectorId);
  }

  const reloadFlight = () => {
    setFlight(undefined);
    setError(undefined);
    credentials && getFlightRequest(credentials);
  }

  const logout = () => {
    setFlight(undefined);
    setError(undefined);
    setCredentials(undefined)
  }

  const closeError = () => {
    setError(undefined);
    credentials && getFlightRequest(credentials);
  }

  const updateCredentials = (credentials: AppCredentials) => {
    setCredentials(credentials);
    getFlightRequest(credentials);
  }

  const toggleShare = () => {
    setShare(!share);
  }

  if (error) {
    return <Error error={error} reloadFlight={reloadFlight} close={closeError} />;
  }

  if (!credentials) {
    return <Login setCredentials={updateCredentials} />;
  }

  if (!flight) {
    return <Preloader />;
  }

  if (share) {
    return <ShareMe close={toggleShare}/>;
  }

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="h-screen mx-auto max-w-md flex flex-col justify-between">
        <div className="border-b border-gray-200 py-6 flex items-center justify-between">
          <h3 className="text-2xl font-medium leading-6 text-gray-900">{flight.pilotName}</h3>
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
            onClick={logout}
          >
            Log out
          </button>
        </div>
        <Main flight={flight} openFlight={openFlight}  closeFlight={closeFlight} />
        <div>
          <div className="text-center mb-4"><button type="button" onClick={toggleShare} className="items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700">Share me!</button></div>
          <div className="text-center text-gray-300"><a href="https://t.me/eventqueue">Have questions?</a> | © 2023</div>
        </div>
      </div>
    </div>
  );
}

export default App;
