import { FunctionComponent, Dispatch } from 'react';
import { XCircleIcon } from '@heroicons/react/20/solid'

type Props = {
  error: AppError;
  reloadFlight: Dispatch<boolean>;
  close: ()=> void;
};

const Error: FunctionComponent<Props> = ({ error, reloadFlight, close }) => {
  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="h-screen mx-auto max-w-md flex flex-col justify-center">
        <div>
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <button className="flex-shrink-0" onClick={close}>
                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
              </button>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">{error.code}</h3>
                <div className="mt-2 text-sm text-red-700">
                  <ul className="list-disc space-y-1 pl-5">
                    {error.message.map((msg) => (<li>{msg}</li>))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-10 flex justify-center" role="status">
            <button
              type="button"
              className="mr-5 inline-flex items-center rounded-md border border-transparent bg-lime-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-lime-700"
              onClick={() => reloadFlight(false)}
            >
              Retry
            </button>
            <button
              type="button"
              className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
              onClick={() => reloadFlight(true)}
            >
              Log out
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error;
