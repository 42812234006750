import { XMarkIcon } from "@heroicons/react/24/outline";
import { QRCodeSVG } from "qrcode.react";

export default function ShareMe({
  close,
}: {
  close: () => void;
}) {

  return (
    <div className="fixed inset-0 bg-white flex justify-center items-center">
      <div className="flex items-center flex-col">
        <QRCodeSVG
          value={"https://shmfethiye.pages.dev/"}
          size={300}
          bgColor={"#ffffff"}
          fgColor={"#2518cd"}
          level={"Q"}
          includeMargin={false}
        />
        <p className="mt-4"><a href="https://shmfethiye.pages.dev/">https://shmfethiye.pages.dev/</a></p>
        <div className="pt-12">
          <button
            type="button"
            className="rotate-180 opacity-40 text-indigo-700 border border-indigo-700 hover:bg-indigo-700 hover:text-white font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center"
            onClick={close}
          >
            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            <span className="sr-only">Back</span>
          </button>
        </div>
      </div>
    </div>
  );
}
